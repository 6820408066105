<template>
  <div class="Tags">
    <transition name="fade" mode="out-in">
      <alert v-if="error" :content="alertContent" @confirm="closeAlert" />
    </transition>
    <div>
      <div class="Grid-row">
        <div class="Grid-column-3">
          <input
            v-model="tagInput"
            class="input"
            type="text"
            placeholder="Search"
            @keyup.enter="searchTag"
          />
        </div>
        <button class="button button-success" type="button" @click="searchTag">
          Search
        </button>
        <button
          class="right button button-primary"
          type="button"
          @click="newTag"
        >
          <i class="icon-plus" /> Create Tag
        </button>
      </div>
      <div class="Grid-row">
        <div class="Grid-column-12">
          <div class="infinite-wrapper">
            <ul class="Tags__list">
              <li class="Tags__list-row Tags__list-row--header">
                <span class="Tags__list-item">Avatar</span>
                <span class="Tags__list-item">Name</span>
                <span class="Tags__list-item">Position</span>
                <span class="Tags__list-item">Post Count</span>
                <span class="Tags__list-item">Custom</span>
              </li>
              <li
                v-for="(tag, index) in tags"
                :key="index"
                class="Tags__list-row"
              >
                <span class="Tags__list-item">
                  <img
                    v-if="tag.imageUrl"
                    :src="tag.imageUrl"
                    class="Tags__list-item-image"
                  />
                  <img
                    v-else
                    src="@/assets/img/avatar.png"
                    class="Tags__list-item-image"
                  />
                </span>
                <span
                  class="Tags__list-item Tags__list-item-link"
                  @click="edit(index)"
                >
                  {{ tag.name }}
                </span>
                <span class="Tags__list-item">{{ tag.position || '-' }}</span>
                <span class="Tags__list-item">{{ tag.postCount }}</span>
                <span class="Tags__list-item">{{ tag.custom }}</span>
              </li>
              <infinite-loading
                ref="infiniteLoading"
                @infinite="infiniteHandler"
              >
                <span slot="no-more">All tags loaded</span>
              </infinite-loading>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import Alert from '@/components/common/navigation/Alert';

export default {
  name: 'Tags',
  components: {
    InfiniteLoading,
    Alert,
  },
  data() {
    return {
      alertContent: 'There were errors processing request. Please try again.',
    };
  },
  computed: {
    tagInput: {
      get() {
        return this.$store.getters.tagInput;
      },
      set(value) {
        this.$store.commit('setTagInput', { value: value });
      },
    },
    tags: {
      get() {
        return this.$store.getters.tags;
      },
    },
    tagsNotLoaded: {
      get() {
        return this.$store.getters.tagsNotLoaded;
      },
    },
    error: {
      get() {
        return this.$store.state.tagError;
      },
    },
  },
  methods: {
    infiniteHandler(state) {
      this.$store.dispatch('getTags').then(() => {
        state.loaded();
        if (!this.tagsNotLoaded) {
          state.complete();
        }
      });
    },
    closeAlert() {
      this.$store.commit('setError', false);
    },
    edit(tagIndex) {
      this.$router.push({ name: 'editTag', params: { tagIndex: tagIndex } });
    },
    newTag() {
      this.$router.push({ name: 'newTag' });
    },
    searchTag() {
      if (!this.tagInput) {
        this.$store.commit('setTagNextToken', { newNextToken: null });
        this.$store.commit('setSearchedTags', { tags: null });
        this.$store.dispatch('getTags');
      } else {
        this.$store.dispatch('searchTagByName');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/tags/Tags.scss';
</style>
